*  <template>
  <v-container>
    <h1>Clientes</h1>

    <v-row class="mt-5">
      <v-col 
        v-for="(client, index) in clients" :key="index"
        cols="12" 
        md="6" 
        lg="3"
      >
        <v-card
          class="mx-auto"
          :title="client.nombre_cliente"
          subtitle="País Origen"
          hover
          @click="toServers(client.cliente_id)"
        >
          <template v-slot:prepend>
            <v-avatar color="light-blue-darken-1">
              <v-icon icon="mdi-briefcase-account"></v-icon>
            </v-avatar>
          </template>
          <template v-slot:append>
            <v-avatar size="15" color="green"></v-avatar>
          </template>
          <!-- <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</v-card-text> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import useAuth from '@/stores/authStore';
import UserService from '@/services/user.service';

const authService = new UserService()
const authStore = useAuth()
const router = useRouter()

let clients = ref([])

onMounted(() => {
  getClients()
});

async function getClients() {
  try {
    let response = await authService.getAllClients()
    clients.value = response;
    console.log('clientes', response);

  } catch (error) {
    console.log('error', error);
  }
}

function toServers(clientID) {
  authStore.clientID = clientID
  router.push(`/servidores/${clientID}`);
}

</script>