<template>
  <v-app-bar class="bg-light-blue-darken-1" :elevation="2">
    <template v-slot:prepend>
      <v-img
        :width="50"
        class="ml-4"
        src="../../assets/iconAware.png"
        style="cursor: pointer;"
        @click="redirectTo()"
      ></v-img>
    </template>

    <v-app-bar-title>Aware Licencias</v-app-bar-title>

    <template v-slot:append>
      <v-btn icon="mdi-logout" @click="logout"></v-btn>
    </template>
  </v-app-bar>
</template>

<script setup>
import { useRouter } from 'vue-router'
import useAuth from '@/stores/authStore';

const authStore = useAuth()
const router = useRouter()

function redirectTo(){
  router.push(`/clientes`);
}

function logout(){
  authStore.isAuth = false
  localStorage.removeItem('token')
  router.push('/')
}



</script>