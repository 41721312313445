import { defineStore } from "pinia";

const useAuth = defineStore("auth", {
  state: () => {
    return {
      isAuth: false,
      clientID: null,
      serverID: null,
      licenseSelected: {}

    };
  },

})

export default useAuth;