<template>
  <v-app>
    <v-main>
      <Navbar v-if="authStore.isAuth" />
      <router-view/>
    </v-main>
  </v-app>
</template>

<script setup>
import Navbar from '@/components/ui/NavbarComponent.vue'
import useAuth from '@/stores/authStore';

const authStore = useAuth()


</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500&display=swap');

#app, h1, h2, h3, h4, h5, h6, p, span, ul, li, a, b, label {
  font-family: 'Nunito', sans-serif !important;
}

.font-20 {
  font-size: 20px;
}
</style>
