<template>
  <v-container>
    <div class="d-flex flex-row">
      <v-btn 
        icon="mdi-keyboard-backspace" 
        color="blue"
        size="small"
        @click="redirectTo()"
      ></v-btn>
      <h1 class="ml-2">Detalle de Licencias</h1>
    </div>
    <h3>Período {{ currentMonth }}-{{ currentYear }}</h3>
    <v-btn prepend-icon="mdi-file-excel" color="green" @click="generateExcel()">Excel</v-btn>
    <v-row class="mt-5">
      <v-col cols="12" md="6">
        <h4>General</h4>
        <v-card>
          <v-table
            height="auto"
            fixed-header
            hover
            ref="tableGeneral"
          >
            <thead>
              <tr>
                <th class="text-center bg-blue font-weight-bold">ID Campaña</th>
                <th class="text-center bg-blue font-weight-bold">Nombre Campaña</th>
                <th class="text-center bg-blue font-weight-bold">Agentes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataGeneral" :key="index">
                <td>{{ item.proyecto_id }}</td>
                <td>{{ item.proyecto_name }}</td>
                <td>{{ item.agentes }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>

      <v-col cols="12">
        <h4>Gestión Campañas por Agentes</h4>
        <v-card>
          <v-table
            height="auto"
            fixed-header
            hover
            ref="tableCampsByAgents"
          >
            <thead>
              <tr>
                <th class="text-center bg-blue font-weight-bold">ID Campaña</th>
                <th class="text-center bg-blue font-weight-bold">Nombre Campaña</th>
                <th v-for="(agent, index) in headersCampsByAgents" :key="index" class="text-center bg-blue font-weight-bold">Agente {{ agent }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataCampByAgents" :key="index">
                <td>{{ item.proyecto_id }}</td>
                <td>{{ item.proyecto_name }}</td>
                <td class="text-center" v-for="(agent, index) in headersCampsByAgents" :key="index">{{ item.agentes[agent] ? item.agentes[agent].cantidad : 0 }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>

      <v-col cols="12">
        <h4>Gestión Diaria por Agentes</h4>
        <v-card>
          <v-table
            height="500"
            fixed-header
            hover
            ref="tableDailyByAgents"
          >
            <thead>
              <tr>
                <th class="text-center bg-blue font-weight-bold">Agentes</th>
                <th v-for="(day, index) in headersDailyByAgents" :key="index" class="text-center bg-blue font-weight-bold">{{ day }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(agent, index) in dataDailyByAgents" :key="index">
                <td class="text-uppercase">{{ listAgents[index] }}</td>
                <td class="text-center" v-for="(item, index) in headersDailyByAgents" :key="index">{{ agent[item] ? agent[item] : 0 }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script setup>

import { ref, onMounted } from 'vue'
import useAuth from '@/stores/authStore';
import UserService from '@/services/user.service';
import * as XLSX from 'xlsx';
import { useRouter } from 'vue-router'

const authService = new UserService()
const authStore = useAuth()
const router = useRouter()

let licenses = ref([])
let dataGeneral = ref([])
let dataCampByAgents = ref([])
let dataDailyByAgents = ref([])
let currentDay = ref(null)
let currentMonth = ref(null)
let currentYear = ref(null)
let headersCampsByAgents = ref([])
let headersDailyByAgents = ref([])
let listAgents = ref([])

let tableGeneral = ref(null)
let tableCampsByAgents = ref(null)
let tableDailyByAgents = ref(null)


onMounted(() => {
  getLicense()
});

async function getLicense(){
  try {
    let response = await authService.getLicense(authStore.serverID, authStore.licenseSelected.ano, authStore.licenseSelected.mes)

    licenses.value = response[0].json_data.data
    console.log('license', licenses.value);

    currentDay.value = licenses.value.Day
    currentMonth.value = licenses.value.Month.toString().padStart(2, '0');
    currentYear.value = licenses.value.Year
    dataGeneral.value = licenses.value.general
    dataCampByAgents.value = licenses.value.proyecto_llamadas
    dataDailyByAgents.value = licenses.value.gestiones_diarias
    listAgents.value = licenses.value.agentes_info

    getHeadersCampByAgents()
    getHeadersDailyByAgents()

  } catch (error) {
    console.log('error', error);
  }
}

function getHeadersCampByAgents(){
  dataCampByAgents.value.forEach(element => {
    Object.keys(element.agentes).forEach(agent => {
      headersCampsByAgents.value.push(agent)
    })
  })
}

function getHeadersDailyByAgents(){
  for(let i = 1; i <= currentDay.value; i++){ 
    let day = i.toString().padStart(2, '0');
    headersDailyByAgents.value.push(`${currentYear.value}-${currentMonth.value}-${day}`)
  }

  for (let index = 1; index <= 3; index++) {
    if(index == 1){
      listAgents.value.gestiones_10 = 'Gestiones < 10'
      
    }else if(index == 2){
      listAgents.value.total_efectivas = 'Total Efectivas'

    }else if(index == 3){
      listAgents.value.total_gestiones = 'Total Gestiones'
    }
  }
}

function generateExcel(){
  console.log('tableGeneral', tableGeneral.value.$el.querySelectorAll('tbody tr'));

  const tableGeneralData = tableGeneral.value.$el.querySelectorAll('tbody tr');
  const tableCampsByAgentsData = tableCampsByAgents.value.$el.querySelectorAll('tbody tr');
  const tableDailyByAgentsData = tableDailyByAgents.value.$el.querySelectorAll('tbody tr');

  const generalSheet = [['ID Campaña', 'Nombre Campaña', 'Agentes']];
  tableGeneralData.forEach(row => {
    const cells = row.querySelectorAll('td');
    generalSheet.push([cells[0].innerText, cells[1].innerText, cells[2].innerText]);
  });

  const campsByAgentsSheet = [['ID Campaña', 'Nombre Campaña', ...headersCampsByAgents.value.map(agent => `Agente ${agent}`)]];
  tableCampsByAgentsData.forEach(row => {
    const cells = row.querySelectorAll('td');
    const rowData = Array.from(cells).map(cell => cell.innerText);
    campsByAgentsSheet.push(rowData);
  });

  const dailyByAgentsSheet = [['Agentes', ...headersDailyByAgents.value]];
  tableDailyByAgentsData.forEach((row) => {
    console.log('index', row);
    const cells = row.querySelectorAll('td');
    const rowData = [...Array.from(cells).map(cell => cell.innerText)];
    dailyByAgentsSheet.push(rowData);
  });


  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(generalSheet), 'General');
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(campsByAgentsSheet), 'Campañas por Agentes');
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(dailyByAgentsSheet), 'Gestión Diaria por Agentes');

  XLSX.writeFile(wb, `${licenses.value.host}_${currentYear.value}_${currentMonth.value}.xlsx`);
}

function redirectTo(){
  router.push(`/servidores/${authStore.clientID}`);
}
</script>