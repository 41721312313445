<template>
  <v-app>
    <v-main>
      <v-form ref="formLogin">
        <div class="bg-image">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="4"
                class="d-flex align-center justify-center"
                style="height: 98vh;"
              >
                <v-card 
                  elevation="6"
                  class="rounded-xl"
                >
                  <v-card-title>
                    <center>
                      <v-img
                        class="mt-5"
                        :width="200"
                        aspect-ratio="16/9"
                        cover
                        src="../assets/logo-aware.png"
                      ></v-img>
                      <p class="text-subtitle-1">Iniciar Sesión</p>
                    </center>
                  </v-card-title>
  
                  <v-card-text>
                    <v-container>
                      <v-row class="mx-2">
                        <v-col cols="12">
                          <v-text-field
                            v-model="name"
                            variant="outlined"
                            hint="Ingresa tu usuario"
                            :rules="nameRules"
                            label="Usuario"
                          ></v-text-field>
                        </v-col>
      
                        <v-col cols="12">
                          <v-text-field
                            v-model="password"
                            :type="showPass ? 'text' : 'password'"
                            variant="outlined"
                            hint="Ingresa tu contraseña"
                            :rules="passRules"
                            :append-inner-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" 
                            @click:append-inner="showPass = !showPass"
                            label="Contraseña"
                          ></v-text-field>
                        </v-col>
      
                        <v-col cols="12">
                          <v-btn block variant="tonal" class="bg-light-blue-darken-1 py-6" @click="validateForm()">
                            LOGIN
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-form>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import useAuth from '@/stores/authStore';
import UserService from '@/services/user.service';

const authService = new UserService()
const authStore = useAuth()
const router = useRouter()

let name = ref('')
let password = ref('')
let nameRules = ref([v => !!v || 'Campo requerido']) 
let passRules = ref([v => !!v || 'Campo requerido'])
let formLogin = ref(null) 
let showPass = ref(false)

onMounted(() => {
  authStore.isAuth = false
});

function validateForm() {
  if(formLogin.value.isValid){
    login()
  }
}

async function login() {
  const data = {
    nombre: name.value,
    password: password.value
  }

  try {
    let response = await authService.login(data)

    console.log('login', response);
    if(response.token){
      authStore.isAuth = true
      localStorage.setItem('token', response.token);
      router.push('/clientes');

    }else{
      console.log('error');
    }

  } catch (error) {
    //alert('Error de autenticación');
    console.log('eee', error);
  }
}
</script>

<style scoped>
.bg-image {
  background-image: url('../assets/back.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
  height: 100vh;
}

/* Ocultar la imagen de fondo en dispositivos móviles */
@media (max-width: 767px) {
  .bg-image {
    background-image: none;
  }
}
</style>

