import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/LoginComponent.vue';
import Clientes from '../components/ClientesComponent.vue';
import Servidores from '../components/ServidoresComponent.vue';
import Detalle from '../components/DetalleComponent.vue';
//import useAuth from '@/stores/authStore';

const routes = [
    { path: '/', component: Login }, // Ruta raíz
    { path: '/clientes', component: Clientes },
    { path: '/servidores/:cliente_id', component: Servidores },
    { path: '/detalle/:servidor_id', component: Detalle },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((routeTo, routeFrom, next) => {
    const publicPages = ["/"];
    const authpage = !publicPages.includes(routeTo.path);
  
    //const authStore = useAuth();
    if (!localStorage.getItem('token') && authpage) {
      return next("/");
    }
  
    next();
});

export default router;