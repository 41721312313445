import http from "../plugins/axios";

class UserService {
  constructor() {}

  async login(data) {
    return http.post("/api/auth/login", data).then((data) => data);
  }

  async getAllClients() {
    return http.get('/api/clientes/1').then((data) => data);
  }

  async getAllServers(clientID) {
    return http.get(`/api/servidores/${clientID}`).then((data) => data);
  }

  async getAllLicenses(serverID) {
    return http.get(`/api/licencias/${serverID}`).then((data) => data);
  }

  async getLicense(serverID, year, month) {
    return http.get(`/api/licencias/${serverID}/${year}/${month}`).then((data) => data);
  }


  
}

export default UserService;
