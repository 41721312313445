<template>
  <v-container>
    <h1>Servidores</h1>
    <v-row class="mt-8">
      <v-col cols="12" md="7">
        <v-row>
          <v-col cols="12" md="7" v-for="(server, index) in servers" :key="index">
            <v-card
              class="mx-auto"
              :class="server.isSelected ? 'bg-grey-lighten-2' : ''"
              :title="server.nombre_servidor"
              :subtitle="server.url"
              hover
              @click="getLicenses(server)"
            >
              <template v-slot:prepend>
                <v-avatar color="orange-darken-1">
                  <v-icon icon="mdi-server"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:append>
                <v-avatar size="15" color="green"></v-avatar>
              </template>
              <!-- <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</v-card-text> -->
            </v-card>
          </v-col>
        </v-row>
      </v-col>
  
      <v-col cols="12" md="5">
        <v-card>
          <v-card-title class="bg-blue">
            <v-icon icon="mdi-server" size="x-small"></v-icon>
            {{ serverSelected.nombre_servidor }}
          </v-card-title>
          <v-table class="text-center" hover density="comfortable">
            <thead>
              <tr>
                <th class="text-center font-weight-bold">Año</th>
                <th class="text-center font-weight-bold">Mes</th>
                <th class="text-center font-weight-bold">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in licenses" :key="index">
                <td>{{ item.ano }}</td>
                <td>{{ item.mes }}</td>
                <td>
                  <v-btn
                    icon="mdi-eye"
                    size="x-small"
                    color="orange"
                    class="text-white"
                    @click="showDetail(item)"
                  >
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
          <center v-if="!licenses.length" class="my-5">
            <h4>No hay registros</h4>
          </center>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import useAuth from '@/stores/authStore';
import UserService from '@/services/user.service';

const authService = new UserService()
const authStore = useAuth()
const router = useRouter()

let servers = ref([]);
let serverSelected = ref({});
let licenses = ref([]);

onMounted(async () => {
  getServers()
});

async function getServers(){
  try {
    let response = await authService.getAllServers(authStore.clientID)
  
    servers.value = response
    console.log('servers', servers.value);

    servers.value.forEach((element) => {
      element.isSelected = false
    })

  } catch (error) {
    console.log('error', error);
  }
}

async function getLicenses(server){
  serverSelected.value = server
  authStore.serverID = server.servidor_id

  servers.value.forEach((element) => {
    element.isSelected = server.servidor_id == element.servidor_id ? true : false
  })

  try {
    let response = await authService.getAllLicenses(server.servidor_id)

    licenses.value = response
    console.log('licenses', licenses.value);

  } catch (error) {
    console.log(error);
  }
}

function showDetail(item){
  authStore.licenseSelected = item
  router.push(`/detalle/${authStore.serverID}`);
}


</script>