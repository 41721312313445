import axios from 'axios';

const http = axios.create();

http.defaults.baseURL = process.env.VUE_APP_API_URL

// Función para obtener el token del local storage
function getTokenFromLocalStorage() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    resolve(token);
  });
}

// Definir rutas que no requieren autenticación
const noAuthRequiredRoutes = ["/api/auth/login"];

// Interceptor de solicitud HTTP
http.interceptors.request.use(async request => {
  //console.log('request', request);
  // Verificar si la ruta de la solicitud actual está en la lista de rutas que no requieren autenticación
  if (!noAuthRequiredRoutes.includes(request.url)) {
    http.defaults.headers.common["Authorization"] = await getTokenFromLocalStorage();
  }
  
  return request;
}, error => {
  return Promise.reject(error);
});

//http.defaults.headers.common['Content-Type'] = 'application/json';

http.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response.status === 401) {
      throw new Error('Unauthorized');
    } else if (!error.response.data.status) {
      return error.response.data;
    } else {
      throw new Error(error.message);
    }
  }
);


export default http;